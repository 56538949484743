export const initStickyTableHeader = () => {
    // VARS
    const tableHeader = document.querySelector('[data-table-header]');
    const stickyTableHeader = document.querySelector('[data-sticky-table-header]');
    if (!tableHeader && !stickyTableHeader) return;
    const table = document.querySelector('[data-table]');
    let coordsTable = getCoords(table);
    let tableHeaderCoords = getCoords(tableHeader);

    // LISTENERS AND CALLS
    window.addEventListener('scroll', handleOnScrollWindow, false);
    window.addEventListener('resize', handleOnResizeWindow, false);

    toggleSticky();

    // HANDLERS
    function handleOnScrollWindow() {
        toggleSticky();
    }

    function handleOnResizeWindow() {
        setCurrentStickyStyle();
    }

    // FUNCTIONS
    function toggleSticky() {
        if (window.scrollY > coordsTable.top + 20 && window.scrollY + 220 < coordsTable.bottom) {
            setStickyStyle();
        } else {
            removeStickyStyle();
        }
    }

    function setStickyStyle() {
        stickyTableHeader.classList.add('is-sticky');
        stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
        stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
    }

    function removeStickyStyle() {
        stickyTableHeader.classList.remove('is-sticky');
        stickyTableHeader.style.left = '';
        stickyTableHeader.style.width = '';
    }

    function setCurrentStickyStyle() {
        coordsTable = getCoords(table);
        tableHeaderCoords = getCoords(tableHeader);
        stickyTableHeader.style.left = tableHeaderCoords.left + 'px';
        stickyTableHeader.style.width = tableHeaderCoords.width + 'px';
    }

    function getCoords(elem) {
        const box = elem.getBoundingClientRect();

        return {
            top: box.top + scrollY,
            left: box.left + scrollY,
            bottom: box.bottom + scrollY,
            right: box.right + scrollY,
            width: box.width,
        };
    }
};
